<template>
  <b-card no-body>
    <b-card-body>
      <title-text />
      <template v-for="(item,key) in dataItem.items">
        <hr
          :key="'hr_' + key"
          :class="key === 0? 'mt-2' : ''"
        >
        <item-element
          :key="key"
          :item-key="key"
          :remove-item="removeItem"
        />
      </template>
    </b-card-body>
    <b-card-footer>
      <b-button
        variant="danger"
        @click="addItem"
      >
        <FeatherIcon icon="PlusCircleIcon" />
        İçerik Ekle
      </b-button>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BButton, BCardFooter, BCardBody,
} from 'bootstrap-vue'
import TitleText from '@/views/Admin/Why_us/elements/TitleText.vue'
import ItemElement from '@/views/Admin/Why_us/elements/ItemElement.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BButton,
    BCardFooter,
    BCardBody,
    TitleText,
    ItemElement,
  },
  computed: {
    dataItem() {
      return this.$store.getters['whyUs/dataItem']
    },
    dataList() {
      return this.$store.getters['whyUsItems/dataList']
    },
  },
  methods: {
    addItem() {
      this.dataItem.items.push({
        id: null,
        title: null,
        content: null,
      })
    },
    removeItem(key) {
      if (this.dataItem.items[key].id) {
        this.$store.dispatch('whyUsItems/removeData', this.dataItem.items[key].id)
          .then(response => {
            if (response) {
              this.dataItem.items.splice(key, 1)
            }
          })
      } else {
        this.dataItem.items.splice(key, 1)
      }
    },
  },
}
</script>
