<template>
  <b-row class="d-flex align-items-center">
    <b-col>
      <b-form-group
        label="Başlık"
        :label-for="'title_' + itemKey"
      >
        <b-form-input
          :id="'title_' + itemKey"
          v-model="dataItem.items[itemKey].title"
          placeholder="Başlık"
        />
      </b-form-group>
      <b-form-group
        label="İçerik"
        :label-for="'content_' + itemKey"
      >
        <b-form-textarea
          :id="'content_' + itemKey"
          v-model="dataItem.items[itemKey].content"
          placeholder="İçerik"
        />
      </b-form-group>
    </b-col>
    <b-col cols="auto">
      <b-button
        variant="flat-danger"
        pill
        class="btn-icon"
        @click="removeItem(itemKey)"
      >
        <FeatherIcon icon="XCircleIcon" />
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import {
  BFormGroup, BFormInput, BFormTextarea, BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  name: 'ItemElement',
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BButton,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
    removeItem: {
      type: Function,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['whyUs/dataItem']
    },
  },
}
</script>
